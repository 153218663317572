import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const colors = {
  primaryBlue: '#0064C8',
  primaryYellow: '#FFAF00',
  secondaryBlue: '#e7f3ff',
  secondaryYellow: `#FEE8B4`,
  white: '#fff',
  darkBlue: '#001D47',
  darkerBlue: '#001631',
  darkYellow: '#e09900',
  text: '#171A21',

  facebook: '#1877f2',
  x: '#000000',
  twitter: '#1da1f2',
  instagram: '#c32aa3',
  youtube: '#ff0000',
  linkedIn: '#0a66c2',
  whatsapp: '#25D366',
  whatsappDark: '#128C7E',
};

const theme = {
  palette: {
    primary: {
      main: colors.primaryBlue,
    },
    secondary: {
      main: colors.primaryYellow,
    },
    text: {
      primary: colors.text,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: ['Mulish', 'sans-serif'].join(','),
    h1: {
      fontSize: '2.5rem',
      fontFamily: 'Mulish',
      color: colors.primaryBlue,
      fontWeight: 800,
    },
    h2: {
      fontSize: '2rem',
      fontFamily: 'Mulish',
      color: colors.primaryBlue,
      fontWeight: 800,
    },
    h3: {
      fontSize: '1.75rem',
      fontFamily: 'Mulish',
      color: colors.primaryBlue,
      fontWeight: 800,
    },
    h4: {
      fontSize: '1.5rem',
      fontFamily: 'Mulish',
      color: colors.primaryBlue,
      fontWeight: 800,
    },
    h5: {
      fontSize: '1.25rem',
      fontFamily: 'Mulish',
      color: colors.primaryBlue,
      fontWeight: 800,
    },
    h6: {
      fontSize: '1rem',
      fontFamily: 'Mulish',
      color: colors.primaryBlue,
      fontWeight: 800,
    },
  },
};

export default responsiveFontSizes(createTheme(theme));
