import React, { ReactElement } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AppSyncApolloProvider } from '../apollo/appSyncApolloProvider';
import theme from '../theme';

const wrapRootElement = ({ element }): ReactElement => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AppSyncApolloProvider>{element}</AppSyncApolloProvider>
  </ThemeProvider>
);

export { wrapRootElement };
