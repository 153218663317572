import React from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from 'aws-amplify';
import fetch from 'cross-fetch';

const authLink = setContext(async (_, { headers }) => {
  const token = (await Auth.currentSession()).getAccessToken().getJwtToken();

  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: { ...headers, Authorization: token },
  };
});

const clientApollo = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    new HttpLink({ uri: process.env.GATSBY_APPSYNC_MEMBER_API_URL, fetch }),
  ]),
  cache: new InMemoryCache(),
});

const AppSyncApolloProvider: any = ({ children }) => {
  return <ApolloProvider client={clientApollo}>{children}</ApolloProvider>;
};

export { AppSyncApolloProvider };
